// SubmittingModal.js
import React from "react";
import "../styles/SubmittingModal.css"; // Create this file for modal styling

function SubmittingModal({ show }) {
  if (!show) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Submitting your records...</h2>
        <p>Please wait while we process your data.</p>
        <p>It may take time...</p>
      </div>
    </div>
  );
}

export default SubmittingModal;
