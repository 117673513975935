import React from "react";
import { Link } from "react-router-dom";
import "../styles/Menu.css";

function AdminMenu({ menuOpen, toggleMenu }) {
  return (
    <div id="menu-container" className={menuOpen ? "open" : ""}>
      <div id="menu-content">
        <h2
          style={{
            color: "#2E7D32",
            fontWeight: "bold",
            fontFamily: "Poppins",
          }}
        >
          MENU
        </h2>
        <ul>
          <li>
            <Link to="/Admin/FetchFields" onClick={toggleMenu}>
              Field Name Dashboard
            </Link>
          </li>
          <li>
            <Link to="/Admin/AdminFieldRecordsDashboard" onClick={toggleMenu}>
              Fetch Field Records by Email
            </Link>
          </li>
          <li>
            <Link to="/Admin/AdminFieldDashboard" onClick={toggleMenu}>
              Fetch Images by Email
            </Link>
          </li>
          <li>
            <Link to="/ApplicationDashboard" onClick={toggleMenu}>
              Application
            </Link>
          </li>
          <li>
            <Link to="/Admin/AdminUserDashboard" onClick={toggleMenu}>
              User Dashboard
            </Link>
          </li>
          {/* <li><Link to="/AddApplication" onClick={toggleMenu}>Add Application</Link></li> */}
          <li>
            <Link to="/FieldDashboard" onClick={toggleMenu}>
              Field Dashboard
            </Link>
          </li>
          {/* <li><Link to="/ImageUpload" onClick={toggleMenu}>Upload Photo</Link></li> */}
          <li>
            <Link to="/About" onClick={toggleMenu}>
              About
            </Link>
          </li>
          <li>
            <Link to="/ContactUs" onClick={toggleMenu}>
              Contact Us
            </Link>
          </li>
          {/* <li><Link to="/Report" onClick={toggleMenu}>Report</Link></li> */}
          <li>
            <Link to="/UserInformation" onClick={toggleMenu}>
              User Information
            </Link>
          </li>
          {/* <li><Link to="/SensorData" onClick={toggleMenu}>Sensor Data</Link></li> */}
        </ul>
      </div>
    </div>
  );
}

export default AdminMenu;
