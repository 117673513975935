import React, { useState } from "react";
import Info from "../../components/Info";

const RainfallRecords = ({
  rainfallRecords,
  setRainfallRecords,
  onPrevious,
  onNext,
}) => {
  const [errors, setErrors] = useState([]);

  // Function to add a new rainfall record
  const addRecord = () => {
    setRainfallRecords([...rainfallRecords, { date: "", amount: "" }]);
  };

  // Function to update an existing rainfall record
  const updateRecord = (index, field, value) => {
    const updatedRecords = [...rainfallRecords];
    updatedRecords[index][field] = value;
    setRainfallRecords(updatedRecords);
  };

  // Function to remove a rainfall record
  const removeRecord = (index) => {
    const updatedRecords = [...rainfallRecords];
    updatedRecords.splice(index, 1);
    setRainfallRecords(updatedRecords);
  };

  // Validate the records before moving to the next step
  const validateRecords = () => {
    const newErrors = [];
    rainfallRecords.forEach((record, index) => {
      if ((record.date && !record.amount) || (!record.date && record.amount)) {
        newErrors.push(index);
      }
    });
    setErrors(newErrors);
    return newErrors.length === 0;
  };

  const handleNext = () => {
    if (validateRecords()) {
      onNext();
    }
  };

  return (
    <div>
      <h2 style={{ display: "flex", justifyContent: "center" }}>
        Rainfall Records
        <Info infoText={`*Required from plant date- end of dry-down period`} />
      </h2>
      <table>
        <thead>
          <tr>
            <th>Date of Rainfall</th>
            <th>Amount (in)</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {rainfallRecords.map((record, index) => (
            <tr key={index}>
              <td>
                <input
                  type="date"
                  value={record.date}
                  onChange={(e) => updateRecord(index, "date", e.target.value)}
                />
              </td>
              <td>
                <input
                  type="number"
                  step="0.01"
                  value={record.amount}
                  onChange={(e) =>
                    updateRecord(index, "amount", e.target.value)
                  }
                />
              </td>
              <td>
                <button onClick={() => removeRecord(index)}>Remove</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {errors.length > 0 && (
        <div style={{ color: "red", whiteSpace: "pre-wrap" }}>
          {errors.map((errorIndex) => (
            <p key={errorIndex}>
              Row {errorIndex + 1}: Both date and amount are required if one is
              filled.
            </p>
          ))}
        </div>
      )}
      <button onClick={addRecord}>Add Record</button>
      <div>
        <button onClick={onPrevious}>Previous</button>
        <button onClick={handleNext}>Next</button>
      </div>
    </div>
  );
};

export default RainfallRecords;
