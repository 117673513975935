import React, { useState } from "react";

const DryDownRecords = ({
  dryDownRecords,
  setDryDownRecords,
  onPrevious,
  onNext,
}) => {
  const [error, setError] = useState("");

  // Function to add a new dry down record
  const addRecord = () => {
    setDryDownRecords([
      ...dryDownRecords,
      { beginDate: "", endDate: "", sign: "", level: "" },
    ]);
    setError(""); // Clear error message if any
  };

  // Function to update an existing dry down record
  const updateRecord = (index, field, value) => {
    const updatedRecords = [...dryDownRecords];
    updatedRecords[index][field] = value;
    setDryDownRecords(updatedRecords);
  };

  // Function to remove a dry down record
  const removeRecord = (index) => {
    if (dryDownRecords.length === 1) {
      setError("At least one dry down record must be present.");
      return;
    }
    const updatedRecords = [...dryDownRecords];
    updatedRecords.splice(index, 1);
    setDryDownRecords(updatedRecords);
  };

  // Validate the records before moving to the next step
  const validateRecords = () => {
    let hasErrors = false;
    const updatedRecords = dryDownRecords.map((record) => {
      if (
        !record.beginDate ||
        !record.endDate ||
        !record.sign ||
        record.level === ""
      ) {
        hasErrors = true;
        return record;
      }
      const level = parseFloat(record.level);
      const signedLevel =
        record.sign === "-" ? -Math.abs(level) : Math.abs(level);
      return { ...record, level: signedLevel };
    });

    if (hasErrors) {
      setError("Please fill out all fields for each dry down record.");
      return false;
    }

    setDryDownRecords(updatedRecords);
    setError("");
    return true;
  };

  const handleNext = () => {
    if (validateRecords()) {
      onNext();
    }
  };

  return (
    <div>
      <h2 style={{ display: "flex", justifyContent: "center" }}>
        Dry Down Records
      </h2>
      <table>
        <thead>
          <tr>
            <th>Dry Down Begin Date</th>
            <th>Dry Down End Date</th>
            <th>Sign</th>
            <th>Dry Down Level (in)</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {dryDownRecords.map((record, index) => (
            <tr key={index}>
              <td>
                <input
                  type="date"
                  value={record.beginDate}
                  onChange={(e) =>
                    updateRecord(index, "beginDate", e.target.value)
                  }
                />
              </td>
              <td>
                <input
                  type="date"
                  value={record.endDate}
                  onChange={(e) =>
                    updateRecord(index, "endDate", e.target.value)
                  }
                />
              </td>
              <td>
                <select
                  value={record.sign}
                  onChange={(e) => updateRecord(index, "sign", e.target.value)}
                >
                  <option value="">Select Sign</option>
                  <option value="+">+</option>
                  <option value="-">-</option>
                </select>
              </td>
              <td>
                <input
                  type="number"
                  step="any"
                  value={record.level}
                  onChange={(e) => updateRecord(index, "level", e.target.value)}
                />
              </td>
              <td>
                <button onClick={() => removeRecord(index)}>Remove</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {error && <p style={{ color: "red", whiteSpace: "pre-wrap" }}>{error}</p>}
      <button onClick={addRecord}>Add Record</button>
      <div>
        <button onClick={onPrevious}>Previous</button>
        <button onClick={handleNext}>Submit</button>
      </div>
    </div>
  );
};

export default DryDownRecords;
