import React, { useState } from "react";

const FieldDetails = ({
  plantDate,
  setPlantDate,
  variety,
  setVariety,
  plantingMethod,
  setPlantingMethod,
  onPrevious,
  onNext,
}) => {
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!plantDate) {
      newErrors.plantDate = "Plant Date is required";
    }
    if (!variety || variety === "Select a variety") {
      newErrors.variety = "Variety is required";
    }
    if (!plantingMethod || plantingMethod === "Select Planting method") {
      newErrors.plantingMethod = "Planting Method is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateForm()) {
      onNext();
    }
  };

  // Sample options for Variety and Planting Method dropdowns
  // Replace these with your actual options, potentially fetched from an API
  const varietyOptions = [
    "Select a variety",
    "Avant",
    "Cheniere",
    "CL111",
    "CL153",
    "CLJ01",
    "CLL16",
    "CLL19",
    "CLM04",
    "Della-2",
    "DG245L",
    "DG263L",
    "DG353M",
    "Jazzman",
    "Jupiter",
    "Mermentau",
    "PVL03",
    "RT 7523 FP",
    "RT3202",
    "RT7301",
    "RT7321 FP",
    "RT7331 MA",
    "RT7401",
    "RT7421 FP",
    "RT7521 FP",
    "RTv7231 MA",
    "Taurus",
    "Titan",
    "XL723",
    "XP753",
    "Other",
  ];

  const plantingMethodOptions = [
    "Select Planting method",
    "Drill Planted",
    "Water Planted",
  ];

  return (
    <div>
      <h2 style={{ display: "flex", justifyContent: "center" }}>
        Field Details
      </h2>
      <form onSubmit={(e) => e.preventDefault()}>
        <div>
          <label htmlFor="plantDate">Plant Date:</label>
          <input
            type="date"
            id="plantDate"
            value={plantDate}
            onChange={(e) => setPlantDate(e.target.value)}
            required
          />
          {errors.plantDate && (
            <p style={{ color: "red" }}>{errors.plantDate}</p>
          )}
        </div>
        <div>
          <label htmlFor="variety">Variety:</label>
          <select
            id="variety"
            value={variety}
            onChange={(e) => setVariety(e.target.value)}
            required
          >
            {varietyOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          {errors.variety && <p style={{ color: "red" }}>{errors.variety}</p>}
        </div>
        <div>
          <label htmlFor="plantingMethod">Planting Method:</label>
          <select
            id="plantingMethod"
            value={plantingMethod}
            onChange={(e) => setPlantingMethod(e.target.value)}
            required
          >
            {plantingMethodOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          {errors.plantingMethod && (
            <p style={{ color: "red" }}>{errors.plantingMethod}</p>
          )}
        </div>
        <div>
          <button type="button" onClick={onPrevious}>
            Previous
          </button>
          <button type="button" onClick={handleNext}>
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default FieldDetails;
