import React, { useState } from "react";
import Info from "../../components/Info";

const PumpingRecords = ({
  pumpingRecords,
  setPumpingRecords,
  onPrevious,
  onNext,
}) => {
  const [error, setError] = useState("");

  // Add a new record
  const addRecord = () => {
    setPumpingRecords([...pumpingRecords, { startDate: "", endDate: "" }]);
  };

  // Update an existing record
  const updateRecord = (index, field, value) => {
    const updatedRecords = [...pumpingRecords];
    updatedRecords[index][field] = value;
    setPumpingRecords(updatedRecords);
  };

  // Remove a record
  const removeRecord = (index) => {
    const updatedRecords = [...pumpingRecords];
    updatedRecords.splice(index, 1);
    setPumpingRecords(updatedRecords);
  };

  // Validate the records before moving to the next step
  const validateRecords = () => {
    for (let record of pumpingRecords) {
      if (!record.startDate || !record.endDate) {
        setError(
          "Please fill out all fields for each pumping record.\n No partial records allowed.\n If you have no records, \nplease click 'Remove' \nto remove the empty record."
        );
        return false;
      }
    }
    if (pumpingRecords.length === 0) {
      setError("Please add at least one pumping record.");
      return false;
    }
    setError("");
    return true;
  };

  const handleNext = () => {
    if (validateRecords()) {
      onNext();
    }
  };

  return (
    <div>
      <h2 style={{ display: "flex", justifyContent: "center" }}>
        Pumping Records
        <Info infoText={`*Required from plant date- end of dry-down period`} />
      </h2>
      <table>
        <thead>
          <tr>
            <th>Pumping Start Date</th>
            <th>Pumping End Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {pumpingRecords.map((record, index) => (
            <tr key={index}>
              <td>
                <input
                  type="date"
                  value={record.startDate}
                  onChange={(e) =>
                    updateRecord(index, "startDate", e.target.value)
                  }
                />
              </td>
              <td>
                <input
                  type="date"
                  value={record.endDate}
                  onChange={(e) =>
                    updateRecord(index, "endDate", e.target.value)
                  }
                />
              </td>
              <td>
                <button onClick={() => removeRecord(index)}>Remove</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {error && <p style={{ color: "red", whiteSpace: "pre-wrap" }}>{error}</p>}
      <button onClick={addRecord}>Add Record</button>
      <div>
        <button onClick={onPrevious}>Previous</button>
        <button onClick={handleNext}>Next</button>
      </div>
    </div>
  );
};

export default PumpingRecords;
