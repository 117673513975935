import React from "react";
import { Link } from "react-router-dom";
import "../styles/Unauthorized.css"; // Assuming you have a CSS file for styling
import SignOut from "../components/SignOut";

const Unauthorized = () => {
  return (
    <div className="unauthorized-container">
      <h1>Access Denied</h1>
      <p>You do not have permission to view this page.</p>
      <p>
        If you want to log out, please click on the right top corner of the
        screen.
      </p>
      <div className="unauthorized-actions">
        <Link to="/ApplicationDashboard" className="btn">
          Go to Dashboard
        </Link>
        <SignOut class="btn" />
      </div>
    </div>
  );
};

export default Unauthorized;
