import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import debounce from "lodash.debounce";
import AdminLayout from "../../components/AdminLayout";
import Applications from "../components/applications";
import EditSvg from "../utils/svg/EditSvg";
import DeleteIcon from "../utils/svg/DeleteSvg";

import {
  Container,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  TablePagination,
} from "@mui/material";
import { motion } from "framer-motion";

function AdminUserDashboard() {
  const [farmers, setFarmers] = useState([]);
  const [selectedFarmer, setSelectedFarmer] = useState(null);
  const [errors, setErrors] = useState({});
  const [newFarmer, setNewFarmer] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    suffix: "",
    dob: "",
    address: "",
    phoneNumber: "",
    email: "",
    roles: [],
  });
  const [loading, setLoading] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  // Add these states at the top of your AdminUserDashboard component
  const [isApplicationsOpen, setIsApplicationsOpen] = useState(false);
  const [selectedFarmerIdForApplications, setSelectedFarmerIdForApplications] =
    useState(null);

  // Pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(0);

  const availableRoles = ["Super_Admin", "Admin", "Grower"];

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const FARMERS_API_URL = `${API_BASE_URL}/api/farmers`;

  useEffect(() => {
    fetchFarmers(searchTerm, page, rowsPerPage);
  }, [page, rowsPerPage]);

  // Debounce the API call to prevent excessive requests
  const debouncedFetchFarmers = useCallback(
    debounce((query) => {
      setPage(0); // Optionally reset to the first page on new search
      fetchFarmers(query, 0, rowsPerPage); // Fetch with new search term
    }, 300),
    [rowsPerPage]
  );

  // Fetch all farmers with pagination
  // Fetch all farmers with pagination and search
  const fetchFarmers = async (
    searchQuery = searchTerm,
    pageNum = page,
    pageSize = rowsPerPage
  ) => {
    setLoading(true);
    try {
      const response = await axios.get(FARMERS_API_URL, {
        params: {
          page: pageNum,
          pageSize: pageSize,
          search: searchQuery,
        },
      });
      setFarmers(response.data.content); // Assuming your API returns a Page object with a content array
      setTotalRows(response.data.totalElements); // Assuming your API returns totalElements for pagination
      setFeedbackMessage("Farmers loaded successfully.");
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Error fetching farmers:", error);
      setErrorMessage("Failed to load farmers.");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  // Add these functions to handle opening and closing the Applications modal
  const handleOpenApplications = (farmerId) => {
    setSelectedFarmerIdForApplications(farmerId);
    setIsApplicationsOpen(true);
  };

  const handleCloseApplications = () => {
    setIsApplicationsOpen(false);
    setSelectedFarmerIdForApplications(null);
  };

  const validateForm = () => {
    let tempErrors = {};

    if (!newFarmer.firstName.trim())
      tempErrors.firstName = "First Name is required";
    if (!newFarmer.lastName.trim())
      tempErrors.lastName = "Last Name is required";
    if (!newFarmer.email.trim()) {
      tempErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(newFarmer.email)) {
      tempErrors.email = "Email is invalid";
    }
    if (!newFarmer.phoneNumber.trim()) {
      tempErrors.phoneNumber = "Phone Number is required";
    } else if (!/^\d{10,15}$/.test(newFarmer.phoneNumber)) {
      tempErrors.phoneNumber = "Phone Number must be between 10 and 15 digits";
    }
    if (!newFarmer.address.trim()) tempErrors.address = "Address is required";
    if (!newFarmer.dob) tempErrors.dob = "Date of Birth is required";
    if (!newFarmer.roles.length)
      tempErrors.roles = "At least one role must be selected";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  // Create or update a farmer
  const handleSaveFarmer = async () => {
    if (!isEditMode && !validateForm()) return; // Prevent submission if validation fails and not in edit mode
    setLoading(true);
    try {
      if (isEditMode) {
        await axios.put(
          `${FARMERS_API_URL}/${selectedFarmer.farmerId}`,
          selectedFarmer
        );
        setFarmers(
          farmers.map((farmer) =>
            farmer.farmerId === selectedFarmer.farmerId
              ? selectedFarmer
              : farmer
          )
        );
        setFeedbackMessage("Farmer updated successfully.");
      } else {
        const response = await axios.post(FARMERS_API_URL, newFarmer);
        setFarmers([...farmers, response.data]);
        setFeedbackMessage("Farmer created successfully.");
      }
      setOpenSnackbar(true);
      handleCloseDialog();
    } catch (error) {
      console.error("Error saving farmer:", error);
      setErrorMessage("Failed to save farmer.");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  // Delete a farmer
  const deleteFarmer = async (id) => {
    setLoading(true);
    try {
      await axios.delete(`${FARMERS_API_URL}/${id}`);
      setFarmers(farmers.filter((farmer) => farmer.farmerId !== id));
      setFeedbackMessage("Farmer deleted successfully.");
      setOpenSnackbar(true);
      fetchFarmers(); // Refresh the list after deletion
    } catch (error) {
      console.error("Error deleting farmer:", error);
      setErrorMessage("Failed to delete farmer. Sensitive data present.");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  // Handle form input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (isEditMode) {
      setSelectedFarmer({ ...selectedFarmer, [name]: value });
    } else {
      setNewFarmer({ ...newFarmer, [name]: value });
    }
  };

  // Handle role selection changes
  const handleRoleChange = (event) => {
    const { value } = event.target;
    const roles = typeof value === "string" ? value.split(",") : value;
    if (isEditMode) {
      setSelectedFarmer({ ...selectedFarmer, roles });
    } else {
      setNewFarmer({ ...newFarmer, roles });
    }
  };

  // Handle search input changes
  const handleSearchChange = (event) => {
    // const searchValue = event.target.value.toLowerCase();
    // setSearchTerm(searchValue);
    // if (searchValue) {
    //   const filtered = farmers.filter(
    //     (farmer) =>
    //       farmer.firstName.toLowerCase().includes(searchValue) ||
    //       farmer.lastName.toLowerCase().includes(searchValue) ||
    //       farmer.email.toLowerCase().includes(searchValue)
    //   );
    //   setFarmers(filtered);
    // } else {
    //   fetchFarmers(); // Re-fetch the paginated list if search is cleared
    // }

    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
    debouncedFetchFarmers(searchValue); // Call debounced fetch
  };

  // Handle page change
  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
    fetchFarmers(searchTerm, newPage, rowsPerPage); // Trigger fetch with new page number
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to the first page
    fetchFarmers(searchTerm, 0, newRowsPerPage); // Trigger fetch with new rows per page
  };

  // Open the dialog for creating or editing a farmer
  const handleOpenDialog = (farmer = null) => {
    if (farmer) {
      setSelectedFarmer(farmer);
      setIsEditMode(true);
    } else {
      setSelectedFarmer(null);
      setIsEditMode(false);
    }
    setIsDialogOpen(true);
  };

  // Close the dialog
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setNewFarmer({
      firstName: "",
      lastName: "",
      middleName: "",
      suffix: "",
      dob: "",
      address: "",
      phoneNumber: "",
      email: "",
      roles: [],
    });
  };

  // Close Snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    setFeedbackMessage("");
    setErrorMessage("");
  };

  return (
    <AdminLayout>
      <Container
        maxwidth="md"
        sx={{
          marginTop: 4,
          overflow: "auto",
          "@media (max-width: 600px)": {
            "& th, & td": {
              fontSize: "0.8rem",
              padding: "6px",
            },
          },
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{ fontFamily: "Poppins, sans-serif" }}
        >
          Admin Dashboard
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpenDialog()}
            disabled={loading}
          >
            Create New Farmer
          </Button>
          <TextField
            label="Search"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            fullWidth
            sx={{ ml: 2 }}
          />
        </Box>

        {loading && (
          <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
            <CircularProgress />
          </Box>
        )}

        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: 400,
              maxwidth: "100%",
              overflow: "auto",
              "@media (max-width: 600px)": {
                "& th, & td": {
                  fontSize: "0.8rem",
                  padding: "6px",
                },
              },
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="center">ID</TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Email</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {farmers.map((farmer) => (
                  <TableRow key={farmer.farmerId}>
                    <TableCell align="right">
                      <Button
                        area-hidden="false"
                        variant="text"
                        onClick={() => handleOpenApplications(farmer.farmerId)}
                        maxwidth="20"
                      >
                        {farmer.farmerId}
                      </Button>
                    </TableCell>
                    <TableCell align="left">{`${farmer.firstName} ${farmer.lastName}`}</TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        maxwidth: 200,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {farmer.email}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        maxwidth: 180,
                        whiteSpace: "nowrap",
                        paddingRight: 0,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          gap: 1,
                        }}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleOpenDialog(farmer)}
                          disabled={loading}
                          sx={{ minWidth: 30, maxwidth: 50 }}
                        >
                          <EditSvg />
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => deleteFarmer(farmer.farmerId)}
                          disabled={loading}
                          sx={{ minWidth: 30, maxwidth: 50 }}
                        >
                          <DeleteIcon />
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={totalRows}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100, 125, 150, 175, 200, 225]} // Adjust options as needed
          />
        </motion.div>

        <Dialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          maxwidth="sm"
          fullWidth
          TransitionComponent={motion.div}
          transition={{ duration: 0.5 }}
        >
          <DialogTitle>
            {isEditMode ? "Edit Farmer" : "Create New Farmer"}
          </DialogTitle>
          <DialogContent dividers sx={{ maxHeight: 400, overflowY: "auto" }}>
            <Box
              component="form"
              sx={{
                display: "grid",
                gap: 2,
                gridTemplateColumns: "repeat(auto-fill, minmax(240px, 1fr))",
              }}
            >
              <TextField
                label="First Name"
                name="firstName"
                value={
                  isEditMode ? selectedFarmer?.firstName : newFarmer.firstName
                }
                onChange={handleInputChange}
                required
                fullWidth
                error={!!errors.firstName}
                helperText={errors.firstName}
              />
              <TextField
                label="Last Name"
                name="lastName"
                value={
                  isEditMode ? selectedFarmer?.lastName : newFarmer.lastName
                }
                onChange={handleInputChange}
                required
                fullWidth
                error={!!errors.lastName}
                helperText={errors.lastName}
              />
              <TextField
                label="Middle Name"
                name="middleName"
                value={
                  isEditMode ? selectedFarmer?.middleName : newFarmer.middleName
                }
                onChange={handleInputChange}
                fullWidth
              />
              <TextField
                label="Suffix"
                name="suffix"
                value={isEditMode ? selectedFarmer?.suffix : newFarmer.suffix}
                onChange={handleInputChange}
                fullWidth
              />
              <TextField
                label="Date of Birth"
                name="dob"
                type="date"
                value={isEditMode ? selectedFarmer?.dob : newFarmer.dob}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                required
                fullWidth
                error={!!errors.dob}
                helperText={errors.dob}
              />
              <TextField
                label="Address"
                name="address"
                value={isEditMode ? selectedFarmer?.address : newFarmer.address}
                onChange={handleInputChange}
                required
                fullWidth
                error={!!errors.address}
                helperText={errors.address}
              />
              <TextField
                label="Phone Number"
                name="phoneNumber"
                value={
                  isEditMode
                    ? selectedFarmer?.phoneNumber
                    : newFarmer.phoneNumber
                }
                onChange={handleInputChange}
                required
                fullWidth
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber}
              />
              <TextField
                label="Email"
                name="email"
                type="email"
                value={isEditMode ? selectedFarmer?.email : newFarmer.email}
                onChange={handleInputChange}
                required
                fullWidth
                error={!!errors.email}
                helperText={errors.email}
              />
              <FormControl fullWidth error={!!errors.roles}>
                <InputLabel>Roles</InputLabel>
                <Select
                  multiple
                  value={isEditMode ? selectedFarmer?.roles : newFarmer.roles}
                  onChange={handleRoleChange}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {availableRoles.map((role) => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
                {errors.roles && (
                  <Typography color="error" variant="caption">
                    {errors.roles}
                  </Typography>
                )}
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={handleSaveFarmer}
              color="primary"
              variant="contained"
              disabled={loading}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          {feedbackMessage ? (
            <Alert
              onClose={handleCloseSnackbar}
              severity="success"
              sx={{ width: "100%" }}
            >
              {feedbackMessage}
            </Alert>
          ) : (
            <Alert
              onClose={handleCloseSnackbar}
              severity="error"
              sx={{ width: "100%" }}
            >
              {errorMessage}
            </Alert>
          )}
        </Snackbar>
      </Container>
      <Applications
        farmerId={selectedFarmerIdForApplications}
        open={isApplicationsOpen}
        onClose={handleCloseApplications}
      />
    </AdminLayout>
  );
}

export default AdminUserDashboard;
