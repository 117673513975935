import React, { useEffect, useState } from "react";
import AdminLayout from "../../components/AdminLayout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/FetchFields.css"; // Import CSS for styling

const FetchInvalidFields = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [fields, setFields] = useState([]);
  const [selectedField, setSelectedField] = useState(null);
  const [selectedFieldName, setSelectedFieldName] = useState(null); // Add this line
  const [records, setRecords] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingRecords, setLoadingRecords] = useState(false);
  const [comments, setComments] = useState("");

  useEffect(() => {
    const fetchFields = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${API_BASE_URL}/fieldnames/invalid-records`
        );
        const data = await response.json();
        setFields(data);
        toast.success("Invalid fields fetched successfully");
      } catch (error) {
        console.error("Error fetching invalid fields:", error);
        toast.error("Error fetching invalid fields");
      } finally {
        setLoading(false);
      }
    };

    fetchFields();
  }, [API_BASE_URL]);

  const handleFieldClick = async (fieldNameId, fieldName) => {
    setLoadingRecords(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/fieldnames/${fieldNameId}/records`
      );
      const data = await response.json();
      if (!data) {
        toast.error("No records found for this field");
        return;
      }
      console.log("Field records:", data);
      console.log("Planting Method:", data.fieldDetail.plantingMethod);
      setRecords(data);
      console.log("\nRecords:", records);
      setSelectedFieldName(fieldName);
      setSelectedField(fieldNameId);
      setShowPopup(true);
      toast.success(`Fetched records for Field ID: ${fieldNameId}`);
    } catch (error) {
      console.error("Error fetching field records:", error);
      toast.error("Error fetching field records");
    } finally {
      setLoadingRecords(false);
    }
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedField(null);
    setSelectedFieldName(null);
    setRecords([]);
    setComments("");
  };

  const handleMarkValid = (fieldNameId) => {
    // Handle mark valid action
    console.log(`Field ${fieldNameId} marked as valid`);
    toast.success(`Field ${fieldNameId} marked as valid`);
  };

  const handleMarkNotValid = (fieldNameId) => {
    // Handle mark not valid action
    console.log(`Field ${fieldNameId} marked as not valid`);
    toast.error(`Field ${fieldNameId} marked as not valid`);
  };

  const handleAddComments = (fieldNameId) => {
    // Handle add comments action
    console.log(`Comments added to field ${fieldNameId}: ${comments}`);
    toast.info(`Comments added to field ${fieldNameId}`);
    setComments("");
  };

  return (
    <AdminLayout>
      <ToastContainer />
      <div className="field-tiles-container">
        {loading ? (
          <div className="loading">Loading fields...</div>
        ) : (
          fields.map((field) => (
            <div key={field.fieldNameId} className="field-tile">
              <span
                className="field-link"
                onClick={() =>
                  handleFieldClick(field.fieldNameId, field.fieldName)
                }
              >
                {field.fieldName}
              </span>
            </div>
          ))
        )}
        {showPopup && records && (
          <div className="popup">
            <div className="popup-content">
              <span className="close-button" onClick={closePopup}>
                &times;
              </span>
              <h3>
                Records for Field {selectedField}: {selectedFieldName}
              </h3>
              {loadingRecords ? (
                <div className="loading">Loading records...</div>
              ) : (
                <>
                  {records.floodDateEstablished && (
                    <table>
                      <thead>
                        <tr>
                          <th colSpan="2">Flood Date Established</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{records.floodDateEstablished}</td>
                        </tr>
                      </tbody>
                    </table>
                  )}

                  {records.fieldDetail && (
                    <table>
                      <thead>
                        <tr>
                          <th colSpan="2">Field Detail</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Plant Date</td>
                          <td>{records.fieldDetail.plantDate}</td>
                        </tr>
                        <tr>
                          <td>Variety</td>
                          <td>{records.fieldDetail.variety}</td>
                        </tr>
                        <tr>
                          <td>Planting Method</td>
                          <td>{records.fieldDetail.plantingMethod}</td>
                        </tr>
                      </tbody>
                    </table>
                  )}

                  {records.pumpingRecords &&
                    records.pumpingRecords.length > 0 && (
                      <table>
                        <thead>
                          <tr>
                            <th colSpan="2">Pumping Records</th>
                          </tr>
                          <tr>
                            <th>Start Date</th>
                            <th>End Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {records.pumpingRecords.map((record, index) => (
                            <tr key={index}>
                              <td>{record.startDate}</td>
                              <td>{record.endDate}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}

                  {records.rainfallRecords &&
                    records.rainfallRecords.length > 0 && (
                      <table>
                        <thead>
                          <tr>
                            <th colSpan="2">Rainfall Records</th>
                          </tr>
                          <tr>
                            <th>Date of Rainfall</th>
                            <th>Amount (in)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {records.rainfallRecords.map((record, index) => (
                            <tr key={index}>
                              <td>{record.dateOfRainfall}</td>
                              <td>{record.amountIn}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}

                  {records.dryDownRecords &&
                    records.dryDownRecords.length > 0 && (
                      <table>
                        <thead>
                          <tr>
                            <th colSpan="3">Dry Down Records</th>
                          </tr>
                          <tr>
                            <th>Begin Date</th>
                            <th>End Date</th>
                            <th>Level (in)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {records.dryDownRecords.map((record, index) => (
                            <tr key={index}>
                              <td>{record.beginDate}</td>
                              <td>{record.endDate}</td>
                              <td>{record.levelIn}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                </>
              )}
              <div className="popup-actions">
                <button onClick={() => handleMarkValid(selectedField)}>
                  Valid
                </button>
                <button onClick={() => handleMarkNotValid(selectedField)}>
                  Not Valid
                </button>
                <div className="comments-section">
                  <textarea
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    placeholder="Add your comments here..."
                  />
                  <button onClick={() => handleAddComments(selectedField)}>
                    Submit Comment
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </AdminLayout>
  );
};

export default FetchInvalidFields;
