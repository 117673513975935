import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import AdminLayout from "../../components/AdminLayout";
import "../../styles/FetchFieldRecords.css";
import { isSuperAdmin } from "../../services/auth";
import Layout from "../../components/Layout";

const FetchFieldRecords = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [email, setEmail] = useState("");
  const [fieldNames, setFieldNames] = useState([]);
  const [selectedFieldName, setSelectedFieldName] = useState("");
  const [details, setDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const LayoutComponent = isSuperAdmin() ? AdminLayout : Layout;

  console.log("email", email);

  useEffect(() => {
    const emailFromLocalStorage = localStorage.getItem("email");
    if (emailFromLocalStorage) {
      setEmail(emailFromLocalStorage);
      handleEmailSearch(emailFromLocalStorage);
    } else {
      console.error("No email found in local storage");
    }
  }, []);

  const handleEmailSearch = async (email) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/fetch-field-names/search-by-email/${email}/field-names`
      );
      const parsedFieldNames = response.data.map((item) => {
        const parts = item.split(",");
        return {
          fieldNameId: parts[2].trim(),
          fieldName: parts[3].trim(),
        };
      });
      setFieldNames(parsedFieldNames);
    } catch (error) {
      console.error("Error fetching field names:", error);
      alert("Failed to fetch field names");
    } finally {
      setIsLoading(false);
    }
  };

  const handleShowDetails = async () => {
    if (!selectedFieldName) return;

    setIsLoading(true);
    try {
      const [
        fieldDetailsResponse,
        pumpingRecordsResponse,
        rainFallResponse,
        dryDownEventsResponse,
      ] = await Promise.all([
        axios.get(
          `${API_BASE_URL}/api/fielddetails/byFieldNameId/${selectedFieldName.fieldNameId}`
        ),
        axios.get(
          `${API_BASE_URL}/api/pumpingrecords/byFieldNameId/${selectedFieldName.fieldNameId}`
        ),
        axios.get(
          `${API_BASE_URL}/api/rainfallrecords/byFieldNameId/${selectedFieldName.fieldNameId}`
        ),
        axios.get(
          `${API_BASE_URL}/api/drydownrecords/byFieldNameId/${selectedFieldName.fieldNameId}`
        ),
      ]);

      const normalizeData = (data) => (Array.isArray(data) ? data : [data]);

      setDetails({
        fieldDetails: normalizeData(fieldDetailsResponse.data),
        pumpingRecords: normalizeData(pumpingRecordsResponse.data),
        rainFallRecords: normalizeData(rainFallResponse.data),
        dryDownRecords: normalizeData(dryDownEventsResponse.data),
      });
    } catch (error) {
      console.error("Error fetching details:", error);
      alert("Field Records not found for the selected field name.");
      setDetails({
        fieldDetails: [],
        pumpingRecords: [],
        rainFallRecords: [],
        dryDownRecords: [],
      });
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (dateArray) => {
    if (!Array.isArray(dateArray) || dateArray.length !== 3)
      return "Invalid Date";
    const [year, month, day] = dateArray;
    return `${String(month).padStart(2, "0")}-${String(day).padStart(
      2,
      "0"
    )}-${year}`;
  };

  return (
    <LayoutComponent>
      <div>
        {/* Email search input and button removed */}
        <div>
          <select
            value={selectedFieldName.fieldNameId || ""}
            onChange={(e) =>
              setSelectedFieldName(
                fieldNames.find((field) => field.fieldNameId === e.target.value)
              )
            }
          >
            <option value="" disabled>
              Select Field Name
            </option>
            {fieldNames.map((field, index) => (
              <option key={index} value={field.fieldNameId}>
                {field.fieldName}
              </option>
            ))}
          </select>
          <button onClick={handleShowDetails} disabled={!selectedFieldName}>
            Show Details
          </button>
        </div>
        {isLoading && <p>Loading...</p>}
        {details && (
          <div className="tables-container">
            <div className="table-wrapper">
              <h3>Field Details</h3>
              <table>
                <thead>
                  <tr>
                    <th>Field Name</th>
                    <th>Date Permanent Flood Established</th>
                    <th>Plant Date</th>
                    <th>Variety</th>
                    <th>Planting Method</th>
                  </tr>
                </thead>
                <tbody>
                  {details.fieldDetails.length > 0 ? (
                    details.fieldDetails.map((record, index) => (
                      <tr key={index}>
                        <td>{record.fieldName.fieldName}</td>
                        <td>{formatDate(record.floodDateEstablished)}</td>
                        <td>{formatDate(record.plantDate)}</td>
                        <td>{record.variety}</td>
                        <td>{record.plantingMethod || "N/A"}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">No field details available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="table-wrapper">
              <h3>Pumping Records</h3>
              <table>
                <thead>
                  <tr>
                    <th>Pumping Start Date</th>
                    <th>Pumping End Date</th>
                  </tr>
                </thead>
                <tbody>
                  {details.pumpingRecords.length > 0 ? (
                    details.pumpingRecords.map((record, index) => (
                      <tr key={index}>
                        <td>{formatDate(record.pumpingStartDate)}</td>
                        <td>{formatDate(record.pumpingEndDate)}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2">No pumping records available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="table-wrapper">
              <h3>Rain Fall Records</h3>
              <table>
                <thead>
                  <tr>
                    <th>Date of Rainfall</th>
                    <th>Amount (inches)</th>
                  </tr>
                </thead>
                <tbody>
                  {details.rainFallRecords.length > 0 ? (
                    details.rainFallRecords.map((record, index) => (
                      <tr key={index}>
                        <td>{formatDate(record.dateOfRainfall)}</td>
                        <td>
                          {record.amountIn !== null ? record.amountIn : "N/A"}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2">No rainfall records available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="table-wrapper">
              <h3>Dry Down Records</h3>
              <table>
                <thead>
                  <tr>
                    <th>Dry Down Begin Date</th>
                    <th>Dry Down End Date</th>
                    <th>Dry Down Level (inches)</th>
                  </tr>
                </thead>
                <tbody>
                  {details.dryDownRecords.length > 0 ? (
                    details.dryDownRecords.map((record, index) => (
                      <tr key={index}>
                        <td>{formatDate(record.dryDownBeginDate)}</td>
                        <td>{formatDate(record.dryDownEndDate)}</td>
                        <td>{record.dryDownLevelIn}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3">No dry down records available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </LayoutComponent>
  );
};

export default FetchFieldRecords;
