import React from "react";

// Assuming props include field data and a method to update the parent state
const FieldInformation = ({
  fieldName,
  fieldData,
  datePermanentFlood,
  onDateChange,
  onNext,
}) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault(); // Prevent form submission
        onNext(); // Go to the next screen
      }}
    >
      <div>
        <label htmlFor="field">Field</label>
        <input
          type="text"
          id="field"
          name="field"
          value={fieldName ? fieldName : ""}
          readOnly // Assuming this is populated from an API and not editable
        />
      </div>
      <div>
        <label htmlFor="datePermanentFlood">
          Date Permanent Flood Established
        </label>
        <input
          type="date"
          id="datePermanentFlood"
          name="datePermanentFlood"
          value={datePermanentFlood}
          onChange={(e) => onDateChange(e.target.value)}
          required
        />
      </div>
      <button type="submit">Next</button>
    </form>
  );
};

export default FieldInformation;
