import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ImageUpload from "./ImageUpload";
import "../../styles/FieldDashboard.css";
import Layout from "../../components/Layout";
import AdminLayout from "../../components/AdminLayout";
import { isSuperAdmin } from "../../services/auth";

const FieldDashboard = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [fields, setFields] = useState([]);
  const [loading, setLoading] = useState(true); // State for loading indicator

  const LayoutComponent = isSuperAdmin() ? AdminLayout : Layout;
  useEffect(() => {
    const fetchData = async () => {
      const farmerId = localStorage.getItem("farmerId");

      const fetchFieldRecordStatus = async (fieldNameId) => {
        try {
          const response = await axios.get(
            `${API_BASE_URL}/records?fieldNameId=${fieldNameId}`
          );
          return response.data.recordsSubmissionSuccess;
        } catch (error) {
          console.error(
            `Failed to fetch field record status for ${fieldNameId}:`,
            error
          );
          return false;
        }
      };

      try {
        const [fieldNamesResponse, sensorsResponse] = await Promise.all([
          axios.get(
            `${API_BASE_URL}/api/fetch-field-names/${farmerId}/field-names`
          ),
          axios.get(`${API_BASE_URL}/api/field-sensors/by-farmer/${farmerId}`),
        ]);

        const sensorsData = sensorsResponse.data;

        const fieldsData = await Promise.all(
          fieldNamesResponse.data.map(async (fieldString, index) => {
            const parts = fieldString.split(",");
            const fieldNameId = parts[1].trim();

            const hasWaterSensor =
              Array.isArray(sensorsData) &&
              sensorsData.some((sensor) => {
                const sensorId = String(sensor[1]).trim();
                return sensorId === fieldNameId && sensor[3] === true;
              });

            try {
              const statusResponse = await axios.get(
                `${API_BASE_URL}/api/images/get?fieldNameId=${fieldNameId}`
              );
              const statusData = statusResponse.data;

              const recordSubmissionSuccess = await fetchFieldRecordStatus(
                fieldNameId
              );

              return {
                id: index + 1,
                applicationId: parts[0].trim(),
                fieldNameId: fieldNameId,
                name: parts.slice(2).join(",").trim(),
                hasWaterSensor: hasWaterSensor,
                records: `Record ${index + 1}`,
                recordSubmissionSuccess: recordSubmissionSuccess,
                submitted: statusData.submissionSuccess || false,
                verificationPending: statusData.verificationPending || false,
                verificationApproved: statusData.verificationApproved || false,
                imageUploaded: statusData.submissionSuccess || false,
              };
            } catch (error) {
              console.error(
                `Failed to fetch image status for field ${fieldNameId}:`,
                error
              );

              const recordSubmissionSuccess = await fetchFieldRecordStatus(
                fieldNameId
              );

              // If image status is not found, return default field object
              return {
                id: index + 1,
                applicationId: parts[0].trim(),
                fieldNameId: fieldNameId,
                name: parts.slice(2).join(",").trim(),
                hasWaterSensor: hasWaterSensor,
                records: `Record ${index + 1}`,
                recordSubmissionSuccess: recordSubmissionSuccess,
                submitted: false,
                verificationPending: false,
                verificationApproved: false,
                imageUploaded: false,
              };
            }
          })
        );

        setFields(fieldsData);
        setLoading(false); // Set loading to false after fetching data
      } catch (error) {
        console.error("Failed to fetch fields:", error);
        setLoading(false); // Set loading to false if there's an error
      }
    };

    fetchData();
  }, []);

  const handleSubmit = (fieldId) => {
    const updatedFields = fields.map((field) =>
      field.id === fieldId
        ? { ...field, submitted: true, verificationPending: true }
        : field
    );
    setFields(updatedFields);
    setTimeout(() => {
      const finalFields = updatedFields.map((field) =>
        field.id === fieldId
          ? { ...field, verificationPending: false, verificationSuccess: true }
          : field
      );
      setFields(finalFields);
      localStorage.setItem(
        `fieldNames_${localStorage.getItem("farmerId")}`,
        JSON.stringify(finalFields)
      );
    }, 3000);
  };

  const handleImageUploadSuccess = async (fieldId) => {
    try {
      // Find the field based on the fieldId
      const field = fields.find((f) => f.fieldNameId === fieldId);
      if (!field) {
        return;
      }
      const response = await axios.post(
        `${API_BASE_URL}/api/images/submit?submissionSuccess=true&verificationApproved=false&verificationPending=true&fieldNameId=${field.fieldNameId}`
      );

      // Handle response based on how your backend manages the state
      if (response.data.submissionSuccess) {
        const updatedFields = fields.map((f) =>
          f.fieldNameId === fieldId
            ? {
                ...f,
                imageUploaded: true,
                verificationPending: true,
                verificationApproved: false,
              }
            : f
        );
        setFields(updatedFields);
      }
    } catch (error) {
      console.error("Failed to submit image for verification:", error);
    }
  };

  const handleDownloadPdf = () => {
    window.open(
      "https://storage.googleapis.com/downloadable-documents-growerportal/Downloadable-Survey-Documents/Single%20Field%20Record%20Sheet-%20SAMPLE.pdf",
      "_blank"
    );
  };

  const handleFetchFieldRecords = () => {
    window.location.href = "/FetchFieldRecords";
  };

  if (loading) {
    return <div>Loading...</div>; // Render loading indicator while data is being fetched
  }

  return (
    <LayoutComponent>
      <div className="dashboard-container">
        <h2 style={{ display: "flex", justifyContent: "center" }}>
          Field Dashboard
        </h2>
        <button className="abc" onClick={handleDownloadPdf}>
          Download Field Record PDF
        </button>
        <button className="abc" onClick={handleFetchFieldRecords}>
          Fetch Field Records
        </button>
        <table className="table">
          <thead>
            <tr className="table-header">
              <th>Field</th>
              <th>Field Records</th>
              <th>Field Record Status</th>
              <th>Image Upload</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {fields.map((field) => (
              <tr key={field.id} className="table-row">
                <td className="table-cell">{field.name}</td>
                <td className="table-cell">
                  <Link
                    to={`/SingleFieldRecord/${
                      field.fieldNameId
                    }/${encodeURIComponent(field.name)}`}
                    className="link"
                  >
                    Field Record
                  </Link>
                </td>
                <td className="table-cell">
                  {field.recordSubmissionSuccess
                    ? "Records Submitted"
                    : "No Records"}
                </td>
                <td
                  className="table-cell"
                  style={{
                    backgroundColor:
                      field.hasWaterSensor == 1 ? "#ccc" : "transparent",
                  }}
                >
                  {field.hasWaterSensor ? (
                    <p style={{ fontWeight: "bold" }}>Water sensor present</p>
                  ) : field.imageUploaded ? (
                    <p>Image Uploaded</p>
                  ) : (
                    <ImageUpload
                      fieldId={field.fieldNameId}
                      farmerId={localStorage.getItem("farmerId")}
                      applicationId={field.applicationId}
                      fieldNameId={field.fieldNameId}
                      onUploadSuccess={() =>
                        handleImageUploadSuccess(field.fieldNameId)
                      }
                    />
                  )}
                </td>
                <td
                  className="table-cell"
                  style={{
                    backgroundColor:
                      field.hasWaterSensor == 1 ? "#ccc" : "transparent",
                  }}
                >
                  {field.hasWaterSensor == 1
                    ? ""
                    : field.verificationPending
                    ? "Verification Pending"
                    : field.verificationApproved
                    ? "Image Approved"
                    : "Image Unverified"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </LayoutComponent>
  );
};

export default FieldDashboard;
