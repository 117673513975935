import React, { useState } from "react";
import axios from "axios";
import AdminLayout from "../../components/AdminLayout";

const AdminDashboard = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [email, setEmail] = useState("");
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailSearch = async (e) => {
    setIsLoading(true); // Start loading

    try {
      // Fetch the currently logged-in farmer's ID from session or authentication data
      const farmerId = localStorage.getItem("farmerId");
      if (!farmerId || ![4, 897, 989].includes(Number(farmerId))) {
        alert("Access denied");
        throw new Error("Access denied");
      }
      const response = await axios.get(
        `${API_BASE_URL}/api/fetch-field-names/search-by-email/${email}/field-names`
      );
      const parsedRecords = response.data.map((item) => {
        const parts = item.split(",");
        return {
          farmerId: parts[0].trim(),
          applicationId: parts[1].trim(),
          fieldNameId: parts[2].trim(),
          fieldName: parts.slice(3).join(",").trim(),
          images: [],
          action: "",
          status: "",
          waterSensorActive: "Fetching...", // Initial placeholder for water sensor status
        };
      });
      await fetchImages(parsedRecords);
      fetchSubmissionStatus(parsedRecords); // Fetch status after images are loaded
      await fetchWaterSensorStatus(parsedRecords);
    } catch (error) {
      console.error("Error fetching records:", error);
      alert("Failed to fetch records");
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const fetchImages = async (fields) => {
    const updatedFields = await Promise.all(
      fields.map(async (field) => {
        const response = await axios.get(
          `${API_BASE_URL}/api/images/field/${field.fieldNameId}`
        );
        field.images = response.data.map((img) => {
          const [id, path] = img.split(",");
          // Replace "gs://" with "https://storage.cloud.google.com/"
          const imagePath = path.replace(
            "gs://",
            "https://storage.cloud.google.com/"
          );
          return { id, path: imagePath };
        });
        return field;
      })
    );
    setRecords(updatedFields);
  };

  const fetchSubmissionStatus = async (fields) => {
    const updatedFields = await Promise.all(
      fields.map(async (field) => {
        try {
          const response = await axios.get(
            `${API_BASE_URL}/api/images/get?fieldNameId=${field.fieldNameId}`
          );
          const statusData = response.data;
          if (
            statusData.verificationApproved === true &&
            statusData.verificationPending === false
          ) {
            field.status = "Approved";
          } else if (
            statusData.verificationApproved === false &&
            statusData.verificationPending === false
          ) {
            field.status = "Rejected";
          } else {
            field.status = "Pending";
          }
          return field;
        } catch (error) {
          // Handle cases where the API returns a 404 or other errors
          if (error.response && error.response.status === 404) {
            console.log(
              `No status found for fieldNameId: ${field.fieldNameId}`
            );
            field.status = "No Data"; // or any other default status
          } else {
            console.error("Error fetching status:", error);
            field.status = "Error"; // Indicate an error fetching the status
          }
          return field;
        }
      })
    );
    setRecords(updatedFields);
  };

  const fetchWaterSensorStatus = async (fields) => {
    if (!fields.length) return; // Exit if no fields to process
    const farmerId = fields[0].farmerId; // Assume all fields have the same farmerId

    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/field-sensors/by-farmer/${farmerId}`
      );
      const sensors = response.data || []; // Ensure sensors is an array, even if API returns null

      // Update records with water sensor status
      const updatedFields = fields.map((field) => {
        const sensor = sensors.find(
          (s) => parseInt(s[1]) === parseInt(field.fieldNameId)
        );
        field.waterSensorActive = sensor ? "Present" : " "; // Update based on presence of sensor data
        return field;
      });

      setRecords(updatedFields); // Ensure to update the state with the new records
    } catch (error) {
      console.error("Error fetching water sensor status:", error);
      // Update all fields to show error in fetching data when an error occurs
      const updatedFields = fields.map((field) => {
        field.waterSensorActive = "Error fetching data";
        return field;
      });
      setRecords(updatedFields);
    }
  };

  const handleActionChange = (index, action) => {
    const newRecords = [...records];
    newRecords[index].action = action;
    setRecords(newRecords);
  };

  const handleSubmit = async (index) => {
    const record = records[index];
    const payload = {
      submissionSuccess: true,
      verificationApproved: record.action === "approve",
      verificationPending: false,
      fieldNameId: record.fieldNameId,
    };

    try {
      const response = await axios.put(
        `${API_BASE_URL}/api/images/update`,
        payload,
        { headers: { "Content-Type": "application/json" } }
      );
      if (response.status === 200) {
        alert("Action submitted successfully");
        // Optionally update status immediately in UI without refetching all statuses
        const updatedRecords = [...records];
        updatedRecords[index].status =
          record.action === "approve" ? "Approved" : "Rejected";
        setRecords(updatedRecords);
      } else {
        alert("Submission failed");
      }
    } catch (error) {
      console.error("Error submitting action:", error);
      alert("Submission failed: " + error.message);
    }
  };

  const handleDeleteImage = async (fieldNameId, imageId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this image?"
    );
    if (!confirmDelete) {
      return; // If the user cancels, exit the function
    }
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/api/images/delete/${imageId}`
      );
      if (response.status === 200) {
        alert("Image deleted successfully");
        // Filter out the deleted image from the state
        const updatedRecords = records.map((record) => {
          if (record.fieldNameId === fieldNameId) {
            const filteredImages = record.images.filter(
              (image) => image.id !== imageId
            );
            return { ...record, images: filteredImages };
          }
          return record;
        });
        setRecords(updatedRecords);
      } else {
        alert("Failed to delete image");
      }
    } catch (error) {
      console.error("Error deleting image:", error);
      alert("Failed to delete image: " + error.message);
    }
  };

  return (
    <AdminLayout>
      <div>
        <input
          type="text"
          placeholder="Enter Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button onClick={handleEmailSearch}>Search</button>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Fieldnames</th>
                <th>Images</th>
                <th>Actions</th>
                <th>Status</th>
                <th>Water Sensor</th>
                <th>Button</th>
              </tr>
            </thead>
            <tbody>
              {records.map((record, index) => (
                <tr key={index}>
                  <td>{record.fieldName}</td>
                  <td>
                    {record.images.map((image, imgIndex) => (
                      <div key={imgIndex} style={{ marginBottom: "10px" }}>
                        <a
                          href={image.path}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Link
                        </a>
                        {" | "}
                        <button
                          onClick={() =>
                            handleDeleteImage(record.fieldNameId, image.id)
                          }
                          style={{
                            border: "none",
                            background: "none",
                            color: "red",
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    ))}
                  </td>
                  <td>
                    <select
                      value={record.action}
                      onChange={(e) =>
                        handleActionChange(index, e.target.value)
                      }
                      disabled={record.waterSensorActive === "Present"} // Disable if water sensor is active
                    >
                      <option value="">Select Action</option>
                      <option value="approve">Approve</option>
                      <option value="reject">Reject</option>
                    </select>
                  </td>
                  <td>{record.status || "Fetching..."}</td>
                  <td>{record.waterSensorActive}</td>
                  <td>
                    <button
                      onClick={() => handleSubmit(index)}
                      disabled={record.waterSensorActive === "Present"} // Disable if water sensor is active
                      style={{
                        cursor:
                          record.waterSensorActive === "Present"
                            ? "not-allowed"
                            : "pointer",
                        backgroundColor:
                          record.waterSensorActive === "Present"
                            ? "#cccccc"
                            : "green",
                      }}
                    >
                      Submit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </AdminLayout>
  );
};

export default AdminDashboard;
