import React from "react";
import "../styles/UserInformation.css";
import Layout from "../components/Layout";
import AdminLayout from "../components/AdminLayout";
import { isSuperAdmin } from "../services/auth";

function UserInformation() {
  const formatDate = (dateString) => {
    if (!dateString || dateString === "null") {
      return "Not Found";
    }

    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() is zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    return `${month}-${day}-${year}`;
  };

  const user = {
    Name:
      localStorage.getItem("firstName") +
      " " +
      localStorage.getItem("lastName"),
    email: localStorage.getItem("email"),
    phoneNumber: localStorage.getItem("phoneNumber"),
    address: localStorage.getItem("address"),
    dob: formatDate(localStorage.getItem("dob")), // Format the DOB here
  };

  const LayoutComponent = isSuperAdmin() ? AdminLayout : Layout;

  return (
    <LayoutComponent>
      <div id="user-info-container">
        <h1>User Information</h1>
        <div className="info-block">
          <span className="user-attribute">Name:</span>
          <p>{user.Name || "Not Found"}</p>
        </div>
        <div className="info-block">
          <span className="user-attribute">Email:</span>
          <p>{user.email || "Not Found"}</p>
        </div>
        <div className="info-block">
          <span className="user-attribute">Phone Number:</span>
          <p>{user.phoneNumber === "null" ? "Not Found" : user.phoneNumber}</p>
        </div>
        <div className="info-block">
          <span className="user-attribute">Address:</span>
          <p>{user.address || "Not Found"}</p>
        </div>
        <div className="info-block">
          <span className="user-attribute">Date of Birth:</span>
          <p>{user.dob}</p>
        </div>
      </div>
    </LayoutComponent>
  );
}

export default UserInformation;
