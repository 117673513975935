import React, { useState } from "react";
import "../../styles/Review.css";

function Review({
  producerInfo,
  rows,
  commodityForm,
  farmDetailsForm,
  formData,
  onPrevious,
  onNext,
  onSubmit,
}) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Process and send formData to the server or handle accordingly
    console.log(formData);
    // Handle Submit button click
    const confirmMessage =
      "Submit Application means you have checked every detail you have entered in the application, and you are confirming the details are true in nature. Do you want to submit the application?";
    const isConfirmed = window.confirm(confirmMessage);
    if (isConfirmed) {
      onButtonSubmit(); // You can pass the forestInfo data to this function if needed
    }
  };

  // Handle actual submission
  const onButtonSubmit = () => {
    // Logic to handle the actual submission, e.g., sending data to a server
    // You can also handle the uploaded document here
    onSubmit();
    // After successful submission:
    setSubmissionMessage("Application Submitted for Verification and Approval");
    setIsSubmitted(true); // Update the submission state
  };

  return (
    <div className="review-container">
      <h2 style={{ textAlign: "center", fontFamily: "sans-serif" }}>
        Review Your Application
      </h2>

      <div>
        <h3 style={{ display: "flex", justifyContent: "center" }}>
          Producer Information
        </h3>
        <table style={{ display: "flex", justifyContent: "center" }}>
          <tbody>
            <tr>
              <td>
                <strong>Producer Name:</strong>
              </td>
              <td>{producerInfo.producerName}</td>
            </tr>
            <tr>
              <td>
                <strong>Producer Entity Name:</strong>
              </td>
              <td>{producerInfo.producerEntityName}</td>
            </tr>
            <tr>
              <td>
                <strong>County of Residence:</strong>
              </td>
              <td>{producerInfo.countyOfResidence}</td>
            </tr>
            <tr>
              <td>
                <strong>Producer Address:</strong>
              </td>
              <td>{producerInfo.producerAddress}</td>
            </tr>
            <tr>
              <td>
                <strong>Is Underserved Small Producer:</strong>
              </td>
              <td>{producerInfo.isUnderservedSmallProducer}</td>
            </tr>
            <tr>
              <td>
                <strong>Baseline Yield:</strong>
              </td>
              <td>{producerInfo.baselineYield}</td>
            </tr>
            <tr>
              <td>
                <strong>Primary Reason For Applying:</strong>
              </td>
              <td>{producerInfo.primaryReasonForApplying}</td>
            </tr>
            <tr>
              <td>
                <strong>Implemented CSAF Practices:</strong>
              </td>
              <td>{producerInfo.implementedCsafPractices}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div>
        <h3>Fields</h3>
        <table>
          <thead>
            <tr>
              <th>Farm ID</th>
              <th>Tract ID</th>
              <th>Field ID</th>
              <th>Acres</th>
              <th>FSA Physical Location</th>
              <th>Field Name</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                <td>{row.farmNumber}</td>
                <td>{row.tractNumber}</td>
                <td>{row.clus.map((clu) => clu.fieldClu).join(", ")}</td>
                <td>{row.clus.map((clu) => clu.acres).join(", ")}</td>
                <td>
                  {row.clus.map((clu) => clu.fsaPhysicalLocation).join(", ")}
                </td>
                <td>{row.clus.map((clu) => clu.fieldName).join(", ")}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div>
        <h3>Field Details</h3>
        <table>
          <thead>
            <tr>
              <th>Farm ID</th>
              <th>Field Name</th>
              <th>Report Qty</th>
              <th>Commodity Category</th>
              <th>Commodity Type</th>
              <th>Field Land Use History</th>
              <th>Field Irrigation History</th>
              <th>Field Tillage History</th>
              <th>Field CSAF Practice History</th>
              <th>Past CSAF Practice History</th>
            </tr>
          </thead>
          <tbody>
            {commodityForm.map((commodity, index) => (
              <tr key={index}>
                <td>{commodity.farmNumber}</td>
                <td>{commodity.fieldName}</td>
                <td>{commodity.reportQtyAcres}</td>
                <td>{commodity.commodityCategory}</td>
                <td>{commodity.commodityType}</td>
                <td>{commodity.landUseHistory}</td>
                <td>{commodity.irrigationHistory}</td>
                <td>{commodity.tillageHistory}</td>
                <td>{commodity.csafPracticeHistory}</td>
                <td>{commodity.pastCsafPracticeHistory}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div>
        <h3>Farm Details</h3>
        <table>
          <thead>
            <tr>
              <th>Farm ID</th>
              <th>Application Acres</th>
              <th>Total Cropland</th>
              <th>Total Land Area</th>
              <th>Produce Livestock</th>
              <th>total Livestock Area</th>
              <th>Livestock Type 1</th>
              <th>Livestock Head 1</th>
              <th>Livestock Type 2</th>
              <th>Livestock Head 2</th>
              <th>Livestock Type 3</th>
              <th>Livestock Head 3</th>
              <th>Total Forest Area</th>
              <th>FSA Physical Location</th>
              <th>Past CSAF Practice</th>
              <th>Transitioning to USDA Certified</th>
            </tr>
          </thead>
          <tbody>
            {farmDetailsForm.map((detail, index) => (
              <tr key={index}>
                <td>{detail.farmNumber}</td>
                <td>{detail.applicationAcres}</td>
                <td>{detail.totalCroplandAcres}</td>
                <td>{detail.totalLandAreaAcres}</td>
                <td>{detail.produceLivestock}</td>
                <td>{detail.totalLiveStockAcres}</td>
                <td>{detail.livestockType1}</td>
                <td>{detail.livestockHead1}</td>
                <td>{detail.livestockType2}</td>
                <td>{detail.livestockHead2}</td>
                <td>{detail.livestockType3}</td>
                <td>{detail.livestockHead3}</td>
                <td>{detail.totalForestAreaAcres}</td>
                <td>{detail.fsaPhysicalLocation}</td>
                <td>{detail.pastCsafPractice}</td>
                <td>{detail.transitioningToUsdaCertified}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="submission-message">{submissionMessage}</div>
      <button onClick={onPrevious}>Previous</button>
      {!isSubmitted && (
        <button onClick={handleSubmit}>Submit Application</button>
      )}
      {isSubmitted && <button onClick={onNext}>Next</button>}
    </div>
  );
}

export default Review;
