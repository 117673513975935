import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { isAuthenticated, getUserRoles } from "./services/auth";

const ProtectedRoutes = ({ requiredRoles }) => {
  const authenticated = isAuthenticated();
  const roles = getUserRoles();

  if (!authenticated) {
    return <Navigate to="/SignIn" />;
  }

  if (requiredRoles && !requiredRoles.some((role) => roles.includes(role))) {
    return <Navigate to="/Unauthorized" />;
  }

  return <Outlet />;
};

export default ProtectedRoutes;
