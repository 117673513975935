import React, { useState } from "react";
import AdminLayout from "../../components/AdminLayout";
import "../styles/ApplicationsDashboard.css";

function ApplicationsDashboard() {
  const [username, setUsername] = useState("");
  const [comments, setComments] = useState("");

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleCommentsChange = (event) => {
    setComments(event.target.value);
  };

  const handleApprove = () => {
    // Logic for approving the application
  };

  const handleReject = () => {
    // Logic for rejecting the application
  };

  return (
    <AdminLayout>
      <div className="Applications">
        <h2>Review Portal</h2>
        <div id="application-details">
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={handleUsernameChange}
          />
        </div>
        <br />
        <textarea
          rows={10}
          cols={100}
          placeholder="Write your comments (MAX 100 words)"
          value={comments}
          onChange={handleCommentsChange}
        ></textarea>
        <br />
        <div id="buttons">
          <button id="accept" onClick={handleApprove}>
            Approve
          </button>
          <button id="reject" onClick={handleReject}>
            Reject
          </button>
        </div>
      </div>
    </AdminLayout>
  );
}

export default ApplicationsDashboard;
