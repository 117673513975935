import React, { useState, useEffect } from "react";
import axios from "axios";
// import axios from 'axios';

const SearchIcon = ({ onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 20 20"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    onClick={onClick}
    style={{ cursor: "pointer" }}
  >
    <circle cx="5" cy="5" r="3"></circle>
    <line x1="12" y1="12" x2="8.65" y2="8.65"></line>
  </svg>
);

function FarmInformation({
  FarmDetailsData,
  farmDetailsForm,
  setFarmDetailsForm,
  onPrevious,
  onNext,
}) {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [totalLandAreaAcres, setTotalLandArea] = useState(null);
  const [farmDetailsRows, setFarmDetailsRows] = useState(
    farmDetailsForm.length > 0 ? farmDetailsForm : FarmDetailsData
  );

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const updatedFarmDetailsRows = [...farmDetailsRows];

    // If the produceLivestock is changed to 'No', set totalLiveStockAcres to 0
    if (name === "produceLivestock" && value === "No") {
      updatedFarmDetailsRows[index].totalLiveStockAcres = 0;
    }

    updatedFarmDetailsRows[index] = {
      ...updatedFarmDetailsRows[index],
      [name]: value,
    };
    setFarmDetailsRows(updatedFarmDetailsRows);
    setFarmDetailsForm(updatedFarmDetailsRows);
  };

  const fetchTotalLandArea = async (farmNumber, index) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/getTotalCalculatedAcreageByFarmNumber?farmNumber=${farmNumber}`
      );
      setTotalLandArea(response.data);
      const updatedFarmDetailsRows = [...farmDetailsRows];
      updatedFarmDetailsRows[index].totalLandAreaAcres =
        response.data.toFixed(3) || 0;
      setFarmDetailsRows(updatedFarmDetailsRows);
    } catch (error) {
      console.error("Error fetching total land area:", error);
    }
  };

  //     // Validation function
  // const validateFields = () => {
  //     for (const farm of farmDetailsRows) {
  //     if (!farm.farmNumber || !farm.applicationAcres || !farm.totalLandAreaAcres || !farm.totalCroplandAcres || !farm.produceLivestock || !farm.totalLiveStockAcres) {
  //         alert('Please fill out all fields before proceeding.');
  //         return false;
  //     }
  //     }
  //     return true;
  // };

  // Handle Next button click with validation
  const handleNext = () => {
    // if (validateFields()) {
    // onNext();
    // }
    onNext();
  };

  return (
    <div className="commodity-information">
      <h1>Farm Information</h1>
      <table>
        <thead>
          <tr>
            <th>Farm ID</th>
            <th>Application Acres</th>
            <th>Total Land Area (Acres)</th>
            <th>Total Cropland (Acres)</th>
            <th>Do you produce livestock on this Farm?</th>
            <th>Total Livestock Area (Acres)</th>
          </tr>
        </thead>
        <tbody>
          {farmDetailsRows.map((farm, index) => (
            <tr key={index}>
              <td>{farmDetailsRows[index]?.farmNumber}</td>
              <td>
                <input
                  type="number"
                  name="applicationAcres"
                  value={farm.applicationAcres}
                  onChange={(e) => handleChange(e, index)}
                  placeholder="Enter Application Acres"
                />
              </td>
              <td>
                <div>
                  <input
                    type="number"
                    name="totalLandAreaAcres"
                    value={farm.totalLandAreaAcres}
                    onChange={(e) => handleChange(e, index)}
                    placeholder="click search icon to get total land area"
                    SearchIcon
                    onClick={() => fetchTotalLandArea(farm.farmNumber, index)}
                  />
                  <SearchIcon
                    onClick={() => fetchTotalLandArea(farm.farmNumber, index)}
                  />
                </div>
              </td>
              <td>
                <input
                  type="number"
                  name="totalCroplandAcres"
                  value={farm.totalCroplandAcres}
                  onChange={(e) => handleChange(e, index)}
                  placeholder="Enter Total Cropland"
                />
              </td>
              <td>
                <select
                  name="produceLivestock"
                  value={farm.produceLivestock}
                  onChange={(e) => handleChange(e, index)}
                >
                  <option value="No">No</option>
                  <option value="Yes">Yes</option>
                </select>
              </td>
              <td>
                <input
                  type="number"
                  name="totalLiveStockAcres"
                  value={farm.totalLiveStockAcres}
                  onChange={(e) => handleChange(e, index)}
                  placeholder="Enter Total Livestock Area (Acres)"
                  readOnly={farm.produceLivestock === "No"}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={onPrevious}>Previous</button>
      <button onClick={handleNext}>Next</button>
    </div>
  );
}

export default FarmInformation;
