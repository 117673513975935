import React, { useEffect, useState } from "react";
import AdminLayout from "../../components/AdminLayout"; // Import Layout component
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/FetchFields.css"; // Import CSS for styling
import FetchImages from "./FetchImages"; // Import AdminDashboard component
import axios from "axios";

const FetchFields = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [fields, setFields] = useState([]);
  const [selectedField, setSelectedField] = useState(null);
  const [selectedFieldName, setSelectedFieldName] = useState(null); // Add this line
  const [selectedProducerEntityName, setSelectedProducerEntityName] =
    useState(null); // Add this line
  const [records, setRecords] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAdminDashboard, setShowAdminDashboard] = useState(false); // Add state for AdminDashboard popup
  const [loadingRecords, setLoadingRecords] = useState(false);
  const [comments, setComments] = useState("");
  const [fieldType, setFieldType] = useState("");
  const [sensorStatuses, setSensorStatuses] = useState({});
  const [allComments, setAllComments] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState("all");

  useEffect(() => {
    if (fieldType === "") return; // Do not fetch if no type is selected
    const fetchFields = async () => {
      setLoading(true);
      try {
        let endpoint;
        switch (fieldType) {
          case "valid":
            endpoint = `${API_BASE_URL}/fieldnames/valid-records`;
            break;
          case "invalid":
            endpoint = `${API_BASE_URL}/fieldnames/invalid-records`;
            break;
          case "sensor-rejected":
            endpoint = `${API_BASE_URL}/fieldnames/sensor-rejected-records`;
            break;
          case "photo-rejected":
            endpoint = `${API_BASE_URL}/fieldnames/photo-rejected-records`;
            break;
          default:
            throw new Error("Invalid field type");
        }
        const response = await fetch(endpoint);
        const data = await response.json();
        console.log(`Fetched ${fieldType} fields:`, data);

        // Ensure `data` is an array
        if (!Array.isArray(data)) {
          throw new Error("Fetched data is not an array");
        }

        setFields(data);
        toast.success(`Fields fetched successfully: ${fieldType} records`);

        // Fetch water sensor statuses
        const statuses = await fetchWaterSensorStatuses(data);
        setSensorStatuses(statuses);
      } catch (error) {
        console.error(`Error fetching ${fieldType} fields:`, error);
        toast.error(`Error fetching ${fieldType} fields`);
        setFields([]); // Ensure fields is set to an empty array on error
      } finally {
        setLoading(false);
      }
    };

    fetchFields();
  }, [fieldType]);

  const fetchWaterSensorStatuses = async (fields) => {
    const statuses = {};
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/field-sensors/all-with-sensors`
      );
      const sensorData = response.data;

      fields.forEach((field) => {
        const matchingSensor = sensorData.find(
          (sensor) => sensor[0] === field.fieldNameId
        );
        statuses[field.fieldNameId] = matchingSensor
          ? "Present"
          : "Not Present";
      });
    } catch (error) {
      console.error("Error fetching water sensor statuses:", error);
      fields.forEach((field) => {
        statuses[field.fieldNameId] = "Error";
      });
    }
    return statuses;
  };

  const handleFieldClick = async (
    fieldNameId,
    fieldName,
    producerEntityName
  ) => {
    setLoadingRecords(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/fieldnames/${fieldNameId}/records`
      );
      const data = await response.json();

      // Check if the data is empty or not an array/object with relevant keys
      if (
        !data ||
        (Array.isArray(data) && data.length === 0) ||
        (typeof data === "object" && Object.keys(data).length === 0)
      ) {
        toast.error("No records found for this field");
        setLoadingRecords(false);
        return;
      }

      setRecords(data);
      setSelectedFieldName(fieldName);
      setSelectedField(fieldNameId);
      setSelectedProducerEntityName(producerEntityName);
      setShowPopup(true);
      toast.success(`Fetched records for Field ID: ${fieldNameId}`);
    } catch (error) {
      console.error("Error fetching field records:", error);
      toast.error("Error fetching field records");
    } finally {
      setLoadingRecords(false);
    }
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedField(null);
    setSelectedFieldName(null);
    setSelectedProducerEntityName(null);
    setRecords([]);
    setComments("");
  };

  const handleMarkValid = async (fieldNameId) => {
    try {
      const payload = {
        fieldNameId: fieldNameId,
        isSubmitted: true,
        isVerified: true,
        isRejected: false,
      };
      const response = await axios.put(
        `${API_BASE_URL}/api/field-record-submissions/update/${fieldNameId}`,
        payload,
        { headers: { "Content-Type": "application/json" } }
      );
      if (response.status === 200) {
        console.log(`Field ${fieldNameId} marked as Approved`);
        toast.success(`Field ${fieldNameId} marked as Approved`);
      } else {
        toast.error(`Failed to mark field ${fieldNameId} as Approved`);
      }
    } catch (error) {
      console.error(`Error marking field ${fieldNameId} as Approved:`, error);
      toast.error(`Error marking field ${fieldNameId} as Approved`);
    }
  };

  const handleMarkNotValid = async (fieldNameId) => {
    // Handle mark not valid action
    try {
      const payload = {
        fieldNameId: fieldNameId,
        isSubmitted: true,
        isVerified: false,
        isRejected: true,
      };
      const response = await axios.put(
        `${API_BASE_URL}/api/field-record-submissions/update/${fieldNameId}`,
        payload,
        { headers: { "Content-Type": "application/json" } }
      );
      if (response.status === 200) {
        console.log(`Field ${fieldNameId} marked as Rejected`);
        toast.success(`Field ${fieldNameId} marked as Rejected`);
      } else {
        toast.error(`Failed to mark field ${fieldNameId} as Rejected`);
      }
    } catch (error) {
      console.error(`Error marking field ${fieldNameId} as  Rejected:`, error);
      toast.error(`Error marking field ${fieldNameId} as Rejected`);
    }
    console.log(`Field ${fieldNameId} marked as Rejected`);
  };

  const handleAddComments = async (fieldNameId) => {
    // Fetch existing comments first
    await fetchComments(fieldNameId);

    try {
      const payload = {
        fieldNameId: fieldNameId,
        comment: comments,
      };
      const response = await axios.post(
        `${API_BASE_URL}/api/comments/add`,
        payload,
        { headers: { "Content-Type": "application/json" } }
      );
      if (response.status === 200) {
        console.log(`Comments added to field ${fieldNameId}: ${comments}`);
        toast.info(`Comments added to field ${fieldNameId}`);
        setComments("");
        fetchComments(fieldNameId); // Fetch comments after adding a new one
      } else {
        toast.error(`Failed to add comments to field ${fieldNameId}`);
      }
    } catch (error) {
      console.error(`Error adding comments to field ${fieldNameId}:`, error);
      toast.error(`Error adding comments to field ${fieldNameId}`);
    }
  };

  const fetchComments = async (fieldNameId) => {
    // Clear allComments before fetching new comments
    setAllComments([]);
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/comments/field/${fieldNameId}`
      );
      if (response.status === 200) {
        setAllComments(response.data);
      } else {
        // Handle error
        console.error(`Failed to fetch comments for field ${fieldNameId}`);
      }
    } catch (error) {
      console.error(`Error fetching comments for field ${fieldNameId}:`, error);
      toast.error(`Error fetching comments for field ${fieldNameId}`);
    }
  };

  useEffect(() => {
    if (selectedField) {
      fetchComments(selectedField); // Fetch comments when selectedField changes
    }
  }, [selectedField]);

  const handleAdminDashboardClick = (fieldNameId) => {
    setSelectedField(fieldNameId);
    setShowAdminDashboard(true);
  };

  const closeAdminDashboard = () => {
    setShowAdminDashboard(false);
    setSelectedField(null);
  };

  const filteredFields = fields
    .filter(
      (field) =>
        field.fieldName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        field.producerEntityName
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    )
    .filter((field) => {
      if (filter === "present") {
        return sensorStatuses[field.fieldNameId] === "Present";
      } else if (filter === "not-present") {
        return sensorStatuses[field.fieldNameId] === "Not Present";
      }
      return true; // If filter is "all" or any other value
    });

  return (
    <AdminLayout>
      <ToastContainer />
      <div className="field-tiles-container">
        <div className="select-container">
          <label htmlFor="fieldType">Select Field Type: </label>
          <select
            id="fieldType"
            value={fieldType}
            onChange={(e) => setFieldType(e.target.value)}
          >
            <option value="">Please select</option>
            <option value="valid">Valid Fields</option>
            <option value="invalid">Invalid Fields</option>
            <option value="sensor-rejected">Sensor Rejected Fields</option>
            <option value="photo-rejected">Photo Rejected Fields</option>
          </select>
        </div>
        <div className="search-container">
          <input
            type="text"
            placeholder="Search Field Names..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="filter-container">
          <label htmlFor="sensorFilter">Filter by Water Sensor: </label>
          <select
            id="sensorFilter"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value="all">All</option>
            <option value="present">Water Sensor Present</option>
            <option value="not-present">Water Sensor Not Present</option>
          </select>
        </div>
        {loading ? (
          <div className="loading">Loading fields...</div>
        ) : (
          filteredFields.map((field) => (
            <div key={field.fieldNameId} className="field-tile">
              <span
                className="field-link"
                onClick={() =>
                  handleFieldClick(
                    field.fieldNameId,
                    field.fieldName,
                    field.producerEntityName
                  )
                }
              >
                {field.fieldName} - {field.producerEntityName}
              </span>
              <span
                className="admin-dashboard-button"
                onClick={() => handleAdminDashboardClick(field.fieldNameId)}
              >
                <button>Fetch Images</button>
              </span>
              <span
                className={
                  sensorStatuses[field.fieldNameId] === "Present"
                    ? "sensor-present"
                    : sensorStatuses[field.fieldNameId] === "Not Present"
                    ? "sensor-not-present"
                    : "sensor-fetching"
                }
              >
                {sensorStatuses[field.fieldNameId] === "Present"
                  ? "Water Sensor Present"
                  : sensorStatuses[field.fieldNameId] === "Not Present"
                  ? "Water Sensor Not Present"
                  : "Fetching Water Sensor Status..."}
              </span>
            </div>
          ))
        )}
        {showPopup && records && (
          <div className="popup">
            <div className="popup-content">
              <span className="close-button" onClick={closePopup}>
                &times;
              </span>
              <h3>
                Records for Field {selectedField}: {selectedFieldName} -{" "}
                {selectedProducerEntityName}
              </h3>
              {loadingRecords ? (
                <div className="loading">Loading records...</div>
              ) : (
                <>
                  {records.floodDateEstablished && (
                    <table>
                      <thead>
                        <tr>
                          <th colSpan="2">Flood Date Established</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{records.floodDateEstablished}</td>
                        </tr>
                      </tbody>
                    </table>
                  )}

                  {records.fieldDetail && (
                    <table>
                      <thead>
                        <tr>
                          <th colSpan="2">Field Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Plant Date</td>
                          <td>{records.fieldDetail.plantDate}</td>
                        </tr>
                        <tr>
                          <td>Variety</td>
                          <td>{records.fieldDetail.variety}</td>
                        </tr>
                        <tr>
                          <td>Planting Method</td>
                          <td>{records.fieldDetail.plantingMethod}</td>
                        </tr>
                      </tbody>
                    </table>
                  )}

                  {records.pumpingRecords &&
                    records.pumpingRecords.length > 0 && (
                      <table>
                        <thead>
                          <tr>
                            <th colSpan="2">Pumping Records</th>
                          </tr>
                          <tr>
                            <th>Start Date</th>
                            <th>End Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {records.pumpingRecords.map((record, index) => (
                            <tr key={index}>
                              <td>{record.startDate}</td>
                              <td>{record.endDate}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}

                  {records.rainfallRecords &&
                    records.rainfallRecords.length > 0 && (
                      <table>
                        <thead>
                          <tr>
                            <th colSpan="2">Rainfall Records</th>
                          </tr>
                          <tr>
                            <th>Date of Rainfall</th>
                            <th>Amount (in)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {records.rainfallRecords.map((record, index) => (
                            <tr key={index}>
                              <td>{record.dateOfRainfall}</td>
                              <td>{record.amountIn}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}

                  {records.dryDownRecords &&
                    records.dryDownRecords.length > 0 && (
                      <table>
                        <thead>
                          <tr>
                            <th colSpan="3">Dry Down Records</th>
                          </tr>
                          <tr>
                            <th>Begin Date</th>
                            <th>End Date</th>
                            <th>Level (in)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {records.dryDownRecords.map((record, index) => (
                            <tr key={index}>
                              <td>{record.beginDate}</td>
                              <td>{record.endDate}</td>
                              <td>{record.levelIn}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                </>
              )}
              <div className="popup-actions">
                <button onClick={() => handleMarkValid(selectedField)}>
                  Approve
                </button>
                <button onClick={() => handleMarkNotValid(selectedField)}>
                  Reject
                </button>
                <div className="comments-section">
                  <textarea
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    placeholder="Add your comments here..."
                  />
                  <button onClick={() => handleAddComments(selectedField)}>
                    Submit Comment
                  </button>
                  <h3>Comments</h3>
                  <ul>
                    {allComments.map((comment) => (
                      <li key={comment.id}>{comment.comment}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
        {showAdminDashboard && (
          <div className="popup">
            <div className="popup-content">
              <span className="close-button" onClick={closeAdminDashboard}>
                &times;
              </span>
              <FetchImages
                fieldNameId={selectedField}
                closeAdminDashboard={closeAdminDashboard}
              />
            </div>
          </div>
        )}
      </div>
    </AdminLayout>
  );
};

export default FetchFields;
