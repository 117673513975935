import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import SignIn from "./pages/SignIn";
import Register from "./pages/Register";
import SignOut from "./components/SignOut";
import ImageUpload from "./pages/ImageUpload";
import About from "./pages/About";
import StepsToFollow from "./pages/StepsToFollow";
import UserInformation from "./pages/UserInformation";
import SensorData from "./pages/SensorData";
import ContactUs from "./pages/ContactUs";
import Report from "./pages/Report";
import AddApplication from "./forms/AddApplication/AddApplication";
import ApplicationDashboard from "./pages/ApplicationDashboard";
import SingleFieldRecord from "./forms/FieldRecords/SingleFieldRecord";
import FieldDashboard from "./forms/FieldRecords/FieldDashboard";
import ProtectedRoutes from "./ProtectedRoute";
import ApplicationsDashboard from "./admin/pages/ApplicationsDashboard";
import AdminFieldDashboard from "./admin/pages/AdminFieldDashboard";
import AdminFieldRecordsDashboard from "./admin/pages/AdminFieldRecordsDashboard";
import FetchFields from "./admin/pages/FetchFields";
import FetchInvalidFields from "./admin/pages/FetchInvalidFields";
import FetchImages from "./admin/pages/FetchImages";
import Unauthorized from "./pages/Unauthorized";
import FetchFieldRecords from "./forms/FieldRecords/FetchFieldRecords";
import AdminUserDashboard from "./admin/pages/AdminUserDashboard";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/SignIn" element={<SignIn />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/Unauthorized" element={<Unauthorized />} />
          <Route element={<ProtectedRoutes requiredRoles={["Super_Admin"]} />}>
            <Route
              path="/Admin/ApplicationsDashboard"
              element={<ApplicationsDashboard />}
            />
            <Route
              path="/Admin/AdminUserDashboard"
              element={<AdminUserDashboard />}
            />
            <Route
              path="/Admin/AdminFieldDashboard"
              element={<AdminFieldDashboard />}
            />
            <Route
              path="/Admin/AdminFieldRecordsDashboard"
              element={<AdminFieldRecordsDashboard />}
            />
            <Route path="/Admin/FetchFields" element={<FetchFields />} />
            <Route
              path="/Admin/FetchInvalidFields"
              element={<FetchInvalidFields />}
            />
            <Route path="/Admin/FetchImages" element={<FetchImages />} />
          </Route>
          <Route
            element={
              <ProtectedRoutes requiredRoles={["Grower", "Super_Admin"]} />
            }
          >
            <Route path="/" element={<Navigate to="/ApplicationDashboard" />} />
            <Route path="/SignOut" element={<SignOut />} />
            <Route path="/ImageUpload" element={<ImageUpload />} />
            <Route path="/About" element={<About />} />
            <Route path="/StepsToFollow" element={<StepsToFollow />} />
            <Route path="/UserInformation" element={<UserInformation />} />
            <Route path="/SensorData" element={<SensorData />} />
            <Route path="/ContactUs" element={<ContactUs />} />
            <Route path="/Report" element={<Report />} />
            <Route path="/AddApplication" element={<AddApplication />} />
            <Route
              path="/ApplicationDashboard"
              element={<ApplicationDashboard />}
            />
            <Route
              path="/SingleFieldRecord/:fieldNameId/:fieldName"
              element={<SingleFieldRecord />}
            />
            <Route path="/FieldDashboard" element={<FieldDashboard />} />
            <Route path="/FetchFieldRecords" element={<FetchFieldRecords />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
