import React, { useState, useEffect } from "react";
// import { useLocation } from 'react-router-dom';
import ProducerInformation from "./ProducerInformation";
import FarmTractCluForm from "./FarmTractCluForm";
import CommodityInformation from "./CommodityInformation";
import FarmInformation from "./FarmInformation";
import LivestockInformation from "./LivestockInformation";
import ForestInformation from "./ForestInformation";
import GrowerSurvey from "./Questioneer";
import Review from "./Review";
import Layout from "../../components/Layout";
import "../../styles/AddApplication.css";
import AdminLayout from "../../components/AdminLayout";
import { isSuperAdmin } from "../../services/auth";

function AddApplication() {
  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);

  const LayoutComponent = isSuperAdmin() ? AdminLayout : Layout;

  // Ensure these parameter names match the ones in your URL
  const [applicationID, setApplicationID] = useState(0); // Keep or adjust as needed
  const [applicationDate, setApplicationDate] = useState("");
  const [status, setStatus] = useState("");
  const [currentScreen, setCurrentScreen] = useState(0); // Start from ScreenZero
  const [producerInfoId, setProducerInfoId] = useState(null);
  const [farmerId, setFarmerId] = useState(localStorage.getItem("farmerId"));

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // Required for Chrome and Firefox
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const [producerInfo, setProducerInfo] = useState({
    producerName: "",
    producerEntityName: "",
    countyOfResidence: "",
    producerAddress: "",
    isUnderservedSmallProducer: "",
    baselineYield: 0,
    primaryReasonForApplying: "",
    implementedCsafPractices: "",
  });
  const [rows, setRows] = useState([]);
  const [farms, setFarms] = useState([
    {
      farmNumber: "",
      tracts: [
        { tractNumber: "", clus: [{ fieldClu: "", acres: "", fieldName: "" }] },
      ],
    },
  ]);

  const farmNumberToFieldNameMapping = rows
    .map((row) => {
      if (row.farmNumber && row.clus.length > 0) {
        return {
          farmNumber: row.farmNumber,
          tractNumber: row.tractNumber,
          cluNumber: row.clus[0].fieldClu,
          fieldName: row.clus[0].fieldName,
          acres: row.clus[0].acres,
          fsaPhysicalLocation: row.clus[0].fsaPhysicalLocation,
        };
      }
      return null;
    })
    .filter(Boolean);

  const [commodityForm, setCommodityForm] = useState([]);
  const [farmDetailsForm, setFarmDetailsForm] = useState([]);

  // Calculate total acres for each unique field name with respect to its farm ID
  useEffect(() => {
    const uniqueFields = {};
    rows.forEach((row) => {
      const farmNumber = row.farmNumber;
      row.clus.forEach((clu) => {
        const fieldName = clu.fieldName;
        if (!uniqueFields[`${farmNumber}-${fieldName}`]) {
          uniqueFields[`${farmNumber}-${fieldName}`] = {
            farmNumber,
            fieldName,
            acres: 0,
          };
        }
        uniqueFields[`${farmNumber}-${fieldName}`].acres += parseFloat(
          clu.acres
        );
      });
    });

    const updatedCommodityData = Object.values(uniqueFields).map((field) => ({
      farmNumber: field.farmNumber,
      fieldName: field.fieldName,
      reportQtyAcres: field.acres.toFixed(3),
      commodityCategory: "Crops",
      commodityType: "Rice",
      landUseHistory: "Crop land",
      irrigationHistory: "Flood/border",
      tillageHistory: "No-till, direct seed",
      csafPracticeHistory: "No",
      pastCsafPracticeHistory: "No",
    }));

    setCommodityData(updatedCommodityData);
  }, [rows]);

  // State to store commodity data
  const [commodityData, setCommodityData] = useState([]);
  const farmNumbersSet = new Set();
  const uniqueFarmNumberToFieldNameMapping = [];
  farmNumberToFieldNameMapping.forEach((farm) => {
    if (!farmNumbersSet.has(farm.farmNumber)) {
      uniqueFarmNumberToFieldNameMapping.push(farm);
      farmNumbersSet.add(farm.farmNumber);
    }
  });

  // Calculate total acres for each farm number
  const calculateTotalCluAcres = () => {
    const totalCluAcresMap = new Map(); // Map to store total clu acres for each farm number
    rows.forEach((row) => {
      const farmNumber = row.farmNumber;
      const clus = row.clus;
      let totalAcres = 0;
      clus.forEach((clu) => {
        totalAcres += parseFloat(clu.acres);
      });
      if (totalCluAcresMap.has(farmNumber)) {
        totalCluAcresMap.set(
          farmNumber,
          totalCluAcresMap.get(farmNumber) + totalAcres
        );
      } else {
        totalCluAcresMap.set(farmNumber, totalAcres);
      }
    });
    return totalCluAcresMap;
  };
  const FarmDetailsData = uniqueFarmNumberToFieldNameMapping.map((farm) => ({
    farmNumber: farm.farmNumber,
    applicationAcres:
      calculateTotalCluAcres().get(farm.farmNumber).toFixed(3) || 0,
    totalCroplandAcres: "0.00",
    totalLandAreaAcres: "",
    produceLivestock: "No",
    totalLiveStockAcres: "0",
    livestockType1: "",
    livestockHead1: "0",
    livestockType2: "",
    livestockHead2: "0",
    livestockType3: "",
    livestockHead3: "0",
    totalForestAreaAcres: "0.00",
    fsaPhysicalLocation: farm.fsaPhysicalLocation,
    pastCsafPractice: "Never Used",
    transitioningToUsdaCertified: "No",
  }));

  const [formData, setFormData] = useState({
    controllingMembersCount: "",
    hasCCC860Certification: "",
    membersContributingToCCC860: "",
    hasParticipatedInLSUMasterFarmerProgram: "",
    membersParticipatedInLSUMasterFarmerProgram: "",
    highestDegreeOfParticipationInMasterFarmerProgram: "",
    yearsOfExperience: "",
    farmedRiceIn2023: "",
    riceAcresFarmedIn2023: "",
    isFirstYearFarmingRice: "",
    mostRecentYearFarmingRice: "",
    riceAcresFarmedInMostRecentYear: "",
    percentageOfIncomeFromOnFarmActivities: "",
    volunteersForEconomicAnalysis: "",
    understandsContractWithSupremeRice: "",
    understandsProhibitionOfDoubleFunding: "",
    understandsPaymentFromSupreme: "",
    hasAuthorityToCompleteApplication: "no",
    ccc860Attachment: null,
    masterFarmerParticipationAttachment: null,
    srNDAAttachment: null,
    srAgreementAttachment: null,
  });

  // Navigate to the next screen
  const goToNextScreen = () => {
    setCurrentScreen(currentScreen + 1);
  };

  // Navigate to the previous screen
  const goToPreviousScreen = () => {
    setCurrentScreen(currentScreen - 1);
  };

  // Function to submit the application data
  const submitApplication = async () => {
    try {
      const farmEntries = uniqueFarmNumberToFieldNameMapping.map((form) => {
        const uniqueTractNumbers = new Set();
        const tracts = rows
          .filter((row) => row.farmNumber === form.farmNumber)
          .filter((row) => {
            if (!uniqueTractNumbers.has(row.tractNumber)) {
              uniqueTractNumbers.add(row.tractNumber);
              return true;
            }
            return false;
          })
          .map((row) => {
            const uniqueCluNumbers = new Set();
            const clus = rows
              .filter((innerRow) => innerRow.tractNumber === row.tractNumber)
              .filter((innerRow) => {
                if (!uniqueCluNumbers.has(innerRow.clus[0]?.fieldClu)) {
                  uniqueCluNumbers.add(innerRow.clus[0]?.fieldClu);
                  return true;
                }
                return false;
              })
              .map((cluRow) => ({
                cluId: 0,
                cluNumber: cluRow.clus[0].fieldClu,
                acres: cluRow.clus[0].acres,
                fsaPhysicalLocation: cluRow.clus[0].fsaPhysicalLocation,
                tractId: 0,
              }));
            return {
              tractId: 0,
              tractNumber: row.tractNumber,
              farmId: 0,
              clu: clus,
            };
          });
        const fieldNameEntries = commodityForm
          .filter((field) => field.farmNumber === form.farmNumber)
          .map((field) => {
            return {
              fieldName: field.fieldName,
              reportQtyAcres: field.reportQtyAcres,
              commodityInfo:
                commodityForm.find(
                  (commodity) => commodity.farmNumber === form.farmNumber
                ) || {},
            };
          });

        return {
          farmId: 0,
          farmNumber: form.farmNumber,
          tract: tracts,
          fieldName: fieldNameEntries,
          farmDetails:
            farmDetailsForm.find(
              (details) => details.farmNumber === form.farmNumber
            ) || {},
        };
      });

      const mappedProducerInfo = {
        ...producerInfo,
        farm: farmEntries,
      };

      // Construct the payload
      const payload = {
        applicationId: 0,
        farmerId: farmerId, // Assuming a static value for demonstration; adjust as necessary.
        producerInfo: mappedProducerInfo,
        applicationDate: new Date().toISOString().slice(0, 10), // Example date; adjust as necessary.
        status: "Submitted",
      };

      // Send the request
      const response = await fetch("${API_BASE_URL}/api/applications", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const responseData = await response.json(); // Parse JSON response
        console.log("Application Submitted Successfully");
        const producerId = responseData.producerInfo.producerInfoId; // Extract producerInfoId
        setProducerInfoId(producerId);
      } else {
        console.error("Failed to submit application");
        alert(
          "Failed to submit application, please check your application with null values"
        );
      }
    } catch (error) {
      console.error("Error submitting application:", error);
      alert("failed to submit application, please try again later");
    }
  };

  useEffect(() => {
    // Load data from localStorage
    const progressKey = `applicationProgress_${farmerId}`;
    const savedProgress = JSON.parse(localStorage.getItem(progressKey));
    if (savedProgress) {
      setFarmerId(savedProgress.farmerId);
      setApplicationID(savedProgress.applicationID);
      setApplicationDate(savedProgress.applicationDate);
      setStatus(savedProgress.status);
      setCurrentScreen(savedProgress.currentScreen);
      setProducerInfo(savedProgress.producerInfo);
      setRows(savedProgress.rows);
      setFarms(savedProgress.farms);
      setCommodityForm(savedProgress.commodityForm);
      setFarmDetailsForm(savedProgress.farmDetailsForm);
      setFormData(savedProgress.formData);
    }
  }, [farmerId]);

  useEffect(() => {
    // Save progress to localStorage
    const progressKey = `applicationProgress_${farmerId}`;
    const progressToSave = {
      farmerId,
      applicationID,
      applicationDate,
      status,
      currentScreen,
      producerInfo,
      rows,
      farms,
      commodityForm,
      farmDetailsForm,
      formData,
    };
    localStorage.setItem(progressKey, JSON.stringify(progressToSave));
  }, [
    farmerId,
    applicationID,
    applicationDate,
    status,
    currentScreen,
    producerInfo,
    rows,
    farms,
    commodityForm,
    farmDetailsForm,
    formData,
  ]);

  return (
    <LayoutComponent>
      <div className="add-application-container">
        {currentScreen === 0 && (
          <ProducerInformation
            producerInfo={producerInfo}
            setProducerInfo={setProducerInfo}
            onNext={goToNextScreen}
          />
        )}
        {currentScreen === 1 && (
          <FarmTractCluForm
            rows={rows}
            setRows={setRows}
            farms={farms}
            setFarms={setFarms}
            onPrevious={goToPreviousScreen}
            onNext={goToNextScreen}
          />
        )}
        {currentScreen === 2 && (
          <CommodityInformation
            CommodityData={commodityData}
            commodityForm={commodityForm}
            setCommodityForm={setCommodityForm}
            onPrevious={goToPreviousScreen}
            onNext={goToNextScreen}
          />
        )}
        {currentScreen === 3 && (
          <FarmInformation
            FarmDetailsData={FarmDetailsData}
            farmDetailsForm={farmDetailsForm}
            setFarmDetailsForm={setFarmDetailsForm}
            onPrevious={goToPreviousScreen}
            onNext={goToNextScreen}
          />
        )}
        {currentScreen === 4 && (
          <LivestockInformation
            FarmDetailsData={FarmDetailsData}
            farmDetailsForm={farmDetailsForm}
            setFarmDetailsForm={setFarmDetailsForm}
            onPrevious={goToPreviousScreen}
            onNext={goToNextScreen}
          />
        )}
        {currentScreen === 5 && (
          <ForestInformation
            FarmDetailsData={FarmDetailsData}
            farmDetailsForm={farmDetailsForm}
            setFarmDetailsForm={setFarmDetailsForm}
            setFarms={setFarms}
            onPrevious={goToPreviousScreen}
            onNext={goToNextScreen}
          />
        )}
        {currentScreen === 6 && (
          <Review
            producerInfo={producerInfo}
            rows={rows}
            farms={farms}
            commodityForm={commodityForm}
            farmDetailsForm={farmDetailsForm}
            formData={formData}
            onPrevious={goToPreviousScreen}
            onNext={goToNextScreen}
            onSubmit={submitApplication}
          />
        )}
        {currentScreen === 7 && (
          <GrowerSurvey
            formData={formData}
            setFormData={setFormData}
            producerInfoId={producerInfoId}
          />
        )}
      </div>
    </LayoutComponent>
  );
}

export default React.memo(AddApplication);
