import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { useNavigate } from "react-router-dom";
import AdminLayout from "../components/AdminLayout";
import { isSuperAdmin } from "../services/auth";

function ApplicationDashboard() {
  const navigate = useNavigate();
  const [surveySubmitted, setSurveySubmitted] = useState(false);
  const [message, setMessage] = useState(false);

  const LayoutComponent = isSuperAdmin() ? AdminLayout : Layout;

  useEffect(() => {
    // Obtain farmerId from localStorage
    const farmerId = localStorage.getItem("farmerId");

    // Check if survey was submitted for this farmerId
    const surveyStatus = localStorage.getItem(`surveySubmitted_${farmerId}`);
    if (surveyStatus === "true") {
      setSurveySubmitted(true);
      setMessage("Application Submitted for Verification and Approval");
    }
  }, []);

  const handleStartOrEditApplication = () => {
    if (!surveySubmitted) {
      navigate("/AddApplication"); // Adjust as necessary
    }
  };

  return (
    <LayoutComponent>
      <div className="add-application-container">
        <h2 style={{ textAlign: "center", fontFamily: "sans-serif" }}>
          My Application
        </h2>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <button
            className="btn btn-primary"
            onClick={handleStartOrEditApplication}
            disabled
            style={{ cursor: "not-allowed", opacity: "0.5" }}
          >
            Start/Edit Application
          </button>
        </div>
        <div>
          <div className="submission-message">{message}</div>
        </div>
      </div>
    </LayoutComponent>
  );
}

export default ApplicationDashboard;
