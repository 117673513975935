import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  CircularProgress,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const Applications = ({ farmerId, open, onClose }) => {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    if (farmerId && open) {
      const fetchData = async () => {
        setLoading(true);
        setError(null); // Reset the error state
        try {
          const response = await fetch(
            `${API_BASE_URL}/api/applications/byFarmerId/${farmerId}`
          );

          if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
          }

          const data = await response.json();
          console.log("API Response Data:", data);

          // Check if the response is an array, or convert it to an array if it's a single object
          const applicationsArray = Array.isArray(data) ? data : [data];
          console.log(
            "Applications array (before setting state):",
            applicationsArray
          );

          if (applicationsArray.length === 0) {
            setError("No applications found for this farmer.");
          } else {
            setApplications(applicationsArray);
          }
        } catch (error) {
          console.error("Error fetching applications:", error);
          setError(error.message || "Failed to load applications.");
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [farmerId, open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Applications for Farmer ID: {farmerId}</DialogTitle>
      <DialogContent dividers sx={{ maxHeight: 400, overflowY: "auto" }}>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
            <Typography color="error">
              {error} Applications not Found
            </Typography>
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Application ID</TableCell>
                  <TableCell>Farmer ID</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Application Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {applications.map((application) => (
                  <TableRow key={application.applicationId}>
                    <TableCell>{application.applicationId}</TableCell>
                    <TableCell>{application.farmerId}</TableCell>
                    <TableCell>{application.status}</TableCell>
                    <TableCell>{application.applicationDate}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Applications;
